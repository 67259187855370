.introContainer{
    position: fixed;
    right: 0px;
    left: 0px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0px 100px;
    background-color: #F7F7F7;
    z-index: 99;
}
.introContainer img{
    cursor: pointer;
}
.introContainer > div{
    display: flex;
    gap: 20px;
    align-items: center;
}
.introContainer :last-child{
    gap: 10px;
}
.introContainer a{
    color: black;
    text-decoration: none;
}
.header{
    position: fixed;
    left: 0px;
    right: 0px;
    top: 50px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 100px;
    border-bottom: #F7F7F7 2px solid;
    background-color: white;
    z-index: 98;
}
.header > div{
    display: flex;
    gap: 40px;
}
.header a{
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.header > a{
    font-weight: 600;
}
.header a:hover{
    color: #3f39a9;
}
#sidebarBtn{
    display: none;
}
.sidebar{
    display: none;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 150px;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    padding: 50px;
    background-color: #F7F7F7;
    z-index: 999;
    box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.139);
    animation: slide-right 500ms forwards;
}
.show{
    display: flex;
}
@keyframes slide-right {
    0%{
      transform: translateX(-100px);
    }
    100%{
      transform: translateX(0px);
    }
  }
@media (max-width: 750px) {
    .introContainer{
        display: none;
    }
    .header{
        position: fixed;
        top: 0px;
        height: 60px;
        justify-content: space-between;
        padding: 0px 30px;
        border-bottom: #F7F7F7 2px solid;
        background-color: white;
        z-index: 98;
    }
    .header img{
        width: 150px;
    }
    .header a{
        display: none;
    }
    #sidebarBtn{
        display: block;
        font-size: 20px;
    }
}